import React from 'react';
import Select from "../../Common/Forms/Select";
import {useFetch} from "../../../hooks/Common/useFetch";
import {getAllCreditNoteTypes} from "../../../services/Treasury/CreditNotesService";

const CreditNoteTypeSelect = ({value, onChange}) => {
    const {data: creditNoteTypes} = useFetch(getAllCreditNoteTypes, {}, [], 'creditNoteTypes');
    return (
        <Select
            label='Tipo de nota de crédito'
            value={value}
            onChange={e => onChange(e.target.value)}
            options={creditNoteTypes}
            fullWidth
        />
    );
};

export default CreditNoteTypeSelect;