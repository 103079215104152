import React from 'react';
import {DataGrid} from "@mui/x-data-grid";
import {useFetch} from "../../../hooks/Common/useFetch";
import {getAllBills} from "../../../services/Treasury/BillsService";
import {parseDateToDateString} from "../../../utils/dates";
import {parseNumberToMoney} from "../../../utils/parsers";
import {useIntl} from "react-intl";
import FilterToolbar from "../../Common/Tables/DataGrid/FilterToolbar";
import {Box} from "@material-ui/core";
import CreditNoteTypeSelect from "./CreditNoteTypeSelect";
import useForm from "../../../hooks/Common/useForm";
import OutlinedDatePicker from "../../Common/Forms/OutlinedDatePicker";

const initData = {
    type: '',
    date: '',
    selectedBill: [],
};

const AddForm = ({onSubmit, id}) => {
    const intl = useIntl();
    const { formData, setFormData, handleSubmit } = useForm(initData);
    const {data: bills} = useFetch(getAllBills, {}, []);
    const columns = [
        {
            field: 'id',
            hide: true,
            filterable: false,
        },
        {
            field: 'creationDate',
            headerName: intl.messages['date'],
            valueFormatter: ({value}) => parseDateToDateString(value),
            width: 200,
        },
        {
            field: 'billNumber',
            headerName: intl.messages['document.number'],
            width: 200,
        },
        {
            field: 'clientName',
            headerName: intl.messages['client'],
            width: 200,
        },
        {
            field: 'total',
            headerName: intl.messages['total'],
            valueFormatter: ({value}) => `${parseNumberToMoney(value)}`,
            width: 200,
            filterable: false,
        },
    ];
    return (
        <form
            id={id}
            onSubmit={handleSubmit(onSubmit)}
        >
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.5rem',
                }}
            >
                <span>Factura Seleccionada: {bills.find(bill => bill.id === formData.selectedBill[0])?.billNumber}</span>
                <CreditNoteTypeSelect
                    value={formData.type}
                    onChange={(value) => setFormData('type', value)}
                />
                <OutlinedDatePicker
                    value={formData.date}
                    onChange={(value) => setFormData('date', value)}
                    label={intl.messages['date']}
                    fullWidth
                />
                <DataGrid
                    columns={columns}
                    rows={bills}
                    style={{
                        minHeight: '40vh',
                    }}
                    selectionModel={formData.selectedBill}
                    onSelectionModelChange={newSelection => setFormData('selectedBill', newSelection)}
                    components={{
                        Toolbar: FilterToolbar,
                    }}
                />
            </Box>
        </form>
    );
};

export default AddForm;