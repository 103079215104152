import React from 'react';
import {Grid} from "@material-ui/core";
import GridContainer from "../../../@jumbo/components/GridContainer";
import ClientSelect
    from "../../ElevatorsManagement/Elevators/DailyOperations/IndexCard/ElevatorDetailDIalog/ElevatorInformation/ClientSelect";
import OutlinedDatePicker from "../../Common/Forms/OutlinedDatePicker";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import CreditNoteTypeSelect from "./CreditNoteTypeSelect";
import OutlinedInput from "../../Common/Forms/OutlinedInput";


const CreditNoteDetails = ({formData, setFormData, isAdd}) => {

    return (
        <GridContainer
            spacing={2}
            style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '1rem'
            }}
        >
            <Grid item xs={12} md={6}>
                <ClientSelect
                    name='client'
                    label={<IntlMessages id='client' />}
                    value={formData.client}
                    setValue={value => setFormData('client', value)}
                    fullWidth
                    autocomplete
                />
            </Grid>
            {!isAdd &&
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        name='creditNoteNumber'
                        label={<IntlMessages id='document.number'/>}
                        value={formData.creditNoteNumber}
                        fullWidth
                        disabled
                    />
                </Grid>
            }
            <Grid item xs={12} md={6}>
                <OutlinedDatePicker
                    name='creationDate'
                    label={<IntlMessages id='date' />}
                    value={formData.creationDate}
                    onChange={value => setFormData('creationDate', value)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <CreditNoteTypeSelect
                    value={formData.creditNoteType}
                    onChange={value => setFormData('creditNoteType', value)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <OutlinedInput
                    name='observations'
                    value={formData.observations}
                    onChange={value => setFormData('observations', value)}
                    label={<IntlMessages id='observations' />}
                    fullWidth
                />
            </Grid>
        </GridContainer>
    );
};

export default CreditNoteDetails;