import axiosInstance from "../../utils/axiosInstance";
import Response from "../../domain/Response";
import {mapToBoolean} from "../../utils/mappers";
import {downloadOptions} from "../../components/Treasury/common/constants";

export const getAllCreditNoteTypes = async () => {
    try {
        const {data} = await axiosInstance.get(
            '/TipoNotaCreditoElectronica/sel',
        );
        return data.map(type => ({
            id: type.vc_cod_tipo_nota_credito_electronica,
            value: type.vc_cod_tipo_nota_credito_electronica,
            name: type.vc_desc_tipo_nota_credito_electronica,
        }));
    } catch (e) {
        return Promise.reject(e);
    }
}

export const getCreditNoteServices = async (creditNoteId) => {
    try {
        const {data} = await axiosInstance.post(
            "/ComprobantePago/get_det",
            {
                nu_id_comprobante: creditNoteId,
            },
        );
        return data.map((service) => ({
            id: service.nu_id_comprobante_pago_det,
            detailId: service.nu_id_comprobante_pago_det,
            serviceId: service.nu_id_servicio,
            serviceName: service.vc_desc_servicio,
            description: service.vc_descripcion_concepto,
            quantity: +service.nu_cantidad,
            unitPrice: +service.nu_valor_unitario,
            discount: +service.nu_porc_descuento,
            total: +service.nu_importe_total,
            tax: service.nu_id_impuesto,
            percentage: service.nu_porc_impuesto,
            taxAmount: service.nu_importe_impuesto,
            applyDeduction: mapToBoolean(service.bi_detraccion),
            saleAmount: +data.nu_precio_venta,
            discountAmount: +data.nu_importe_descuento,
            unitPriceWithDiscount: +data.nu_precio_con_descuento,
            subtotal: +data.nu_importe_neto,
        }));
    } catch (e) {
        return Promise.reject(e);
    }
}

const mapServices = (services) => {
    let subtotal = 0;
    let taxAmount = 0;
    let total = 0;
    const mappedServices = services.map(service => {
        const serviceSubtotal = service.subtotal ?? (service.unitPrice * service.quantity) * (1 - service.discount / 100)
        const serviceTaxAmount = service.taxAmount ?? (serviceSubtotal * service.percentage / 100);
        const serviceToTal = service?.total?.toFixed(2);
        subtotal += +serviceSubtotal;
        taxAmount += +serviceTaxAmount;
        total += +service?.total || 0;
        return {
            nu_id_servicio: service.serviceId,
            nu_cantidad: service.quantity,
            nu_valor_unitario: service.unitPrice,
            nu_porc_descuento: service.discount,
            nu_importe_total: serviceToTal,
            vc_descripcion_concepto: service.description,
            nu_id_impuesto: service.tax,
            nu_porc_impuesto: service.percentage,
            nu_importe_impuesto: serviceTaxAmount,
            nu_id_comprobante_det: service.detailId || null,
            nu_importe_descuento: service.discountAmount,
            nu_precio_venta: service.saleAmount,
            nu_precio_con_descuento: service.unitPriceWithDiscount,
            nu_importe_neto: serviceSubtotal,

        }
    });
    return {
        services: mappedServices,
        subtotal,
        taxAmount,
        total,
    }
}

export const addCreditNote = async ({request}) => {
    try {
        const {data} = await axiosInstance.post(
            '/ComprobantePago/ins_nota_credito',
            {
                nu_id_comprobante_pago_ref: request.selectedBill?.at(0),
                dt_fecha: request.date,
                nu_id_tipo_nota_credito_electronica: request.type,
            }
        );
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    } catch (e) {
        return Promise.reject(e);
    }
}

export const editCreditNote = async ({request, type = 1}) => {
    try {
        const {services, taxAmount, subtotal, total} = mapServices(Object.values(request.services));
        const discountAmount = total * ((+request?.discount || 0) / 100);
        const {data} = await axiosInstance.post(
            '/ComprobantePago/upd',
            {
                nu_id_comprobante: request.id,
                vc_nro_documento: request.creditNoteNumber,
                nu_id_cliente: request.client.id,
                dt_fecha: request.creationDate,
                nu_id_tipo_documento: type,
                nu_sub_total: subtotal,
                nu_impuesto: taxAmount,
                nu_descuento: (request.discount || 0),
                nu_importe_descuento: discountAmount,
                nu_total: total,
                nu_total_con_descuento: total - discountAmount,
                ls_det: services,
                bi_detraccion: request.applyDeduction,
            }
        );
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    } catch (e) {
        return Promise.reject(e);
    }
}
export const deleteCreditNote = async ({creditNoteId}) => {
    try {
        const {data} = await axiosInstance.post(
            '/ComprobantePago/del',
            {
                nu_id_comprobante: creditNoteId,
            }
        );
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    } catch (e) {
        return Promise.reject(e);
    }
}

export const sentSunatCreditNote = async ({creditNoteId}) => {
    try {
        const {data} = await axiosInstance.post(
            '/ComprobantePago/send_fe',
            {
                nu_id_comprobante: creditNoteId,
            }
        );
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    } catch (e) {
        return Promise.reject(e);
    }
}

export const cancelCreditNote = async ({creditNoteId}) => {
    try {
        const {data} = await axiosInstance.post(
            '/ComprobantePago/anu_fe',
            {
                nu_id_comprobante: creditNoteId,
            }
        );
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    } catch (e) {
        return Promise.reject(e);
    }
}

export const getCreditNoteServiceCalc = async ({service}) => {
    try {
        const {data} = await axiosInstance.post(
            '/ComprobantePago/obtener_detalle_add',
            {
                nu_id_servicio: service.serviceId,
                nu_cantidad: service.quantity,
                nu_valor_unitario: service.unitPrice,
                nu_porc_desctuento: service.discount,
                nu_id_impuesto: service.tax,
            }
        );
        return {
            serviceId: data.nu_id_servicio,
            quantity: data.nu_cantidad,
            unitPrice: data.nu_valor_unitario,
            discount: data.nu_porc_descuento,
            saleAmount: +data.nu_precio_venta,
            discountAmount: +data.nu_importe_descuento,
            unitPriceWithDiscount: +data.nu_precio_con_descuento,
            subtotal: +data.nu_importe_neto,
            taxAmount: +data.nu_importe_impuesto,
            total: +data.nu_importe_total,
        };
    } catch (e) {
        return Promise.reject(e);
    }
}


export const fetchCreditNoteFile = async ({creditNoteId, fileType}) => {
    try {
        const {data} = await axiosInstance.post(
            `/ComprobantePago/descargar_${fileType.toLowerCase()}`,
            {
                arr_id_comp_pago: creditNoteId,
            }
        );
        if(fileType === downloadOptions.PDF) {
            return `data:application/pdf;base64,${data?.base64}`;
        }
        if(fileType === downloadOptions.XML) {
            return `data:text/xml;base64,${data?.base64}`;
        }
        return `data:application/x-cdr;base64,${data?.base64}`;
    } catch (e) {
        return Promise.reject(e);
    }
}

