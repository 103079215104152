import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {dialogsList} from "./constants";
import {closeDialog, setCurrentCreditNote} from "../../../redux/reducers/Treasury/creditNotes";
import {updateCreditNote} from "../../../redux/thunks/Treasury/creditNotes";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import FormDialog from "../../Common/Dialogs/FormDialog";
import {parseDateToInput} from "../../../utils/dates";
import {cloneDeep} from "lodash";
import EditForm from "./EditForm";

const EditDialog = () => {
    const id = 'edit-credit-note-form';
    const {dialogs, currentCreditNote} = useSelector(({treasury}) => treasury.creditNotes);
    const dispatch = useDispatch();
    const open = dialogs.some(dialog => dialog === dialogsList.EDIT_CREDIT_NOTE);
    const disableEdit = !currentCreditNote?.state?.toLowerCase()?.includes('pendiente');
    const onSubmit = (data) => {
        dispatch(updateCreditNote({
            request: {
                ...currentCreditNote,
                ...data,
            }
        }));
        onClose();
    };
    const onClose = () => {
        dispatch(closeDialog(dialogsList.EDIT_CREDIT_NOTE));
        dispatch(setCurrentCreditNote(null));
    }
    return (
        <>
            <FormDialog
                id={id}
                isOpen={open}
                onClose={onClose}
                title={<IntlMessages id="edit"/>}
                fullScreen
                disabled={disableEdit}
            >
                {(open && currentCreditNote) &&
                    <EditForm
                        id={id}
                        onSubmit={onSubmit}
                        initialData={{
                            id: currentCreditNote.id,
                            creditNoteNumber: currentCreditNote.billNumber,
                            client: {
                                id: currentCreditNote.clientId,
                                name: currentCreditNote.clientName,
                            },
                            creationDate: parseDateToInput(currentCreditNote.creationDate),
                            discount: currentCreditNote.discount,
                            services: cloneDeep(currentCreditNote.services),
                            observations: currentCreditNote.observations,
                        }}
                        disableEdit={disableEdit}
                    />
                }
            </FormDialog>
        </>
    );
};

export default EditDialog;