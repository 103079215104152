import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {DataGrid} from "@mui/x-data-grid";
import {useIntl} from "react-intl";
import {Box, IconButton, Typography} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {openDialog} from "../../../redux/reducers/Treasury/creditNotes";
import {parseNumberToMoney} from "../../../utils/parsers";
import ContainedButton from "../../Common/Buttons/ContainedButton";
import {parseDateToDateString} from "../../../utils/dates";
import ShowIcon from "@material-ui/icons/Visibility";
import FilterToolbar from "../../Common/Tables/DataGrid/FilterToolbar";
import CancelIcon from '@material-ui/icons/Cancel';
import {
    cancelSentCreditNote,
    fetchAllCreditNotes,
    getAndSetCreditNoteDetails,
    removeCreditNote,
    sendSunatSelectedCreditNotes
} from "../../../redux/thunks/Treasury/creditNotes";
import {dialogsList} from "./constants";
import DownloadMenu from "../common/DownloadMenu";

const Table = () => {
    const dispatch = useDispatch();
    const creditNotes = useSelector(({treasury}) => treasury.creditNotes.creditNotes);
    const creditNotesList = Object.values(creditNotes);
    const [selectedCreditNotes, setSelectedCreditNotes] = React.useState([]);
    const intl = useIntl();
    React.useEffect(() => {
        dispatch(fetchAllCreditNotes());
    }, []);
    const handleEditTax = (row) => {
        dispatch(openDialog(dialogsList.EDIT_CREDIT_NOTE));
        dispatch(getAndSetCreditNoteDetails({creditNote: row}));
    }
    const handleDeleteTax = (row) => {
        dispatch(removeCreditNote({creditNoteId: row.id}))
            .unwrap()
            .finally(() => {
                setSelectedCreditNotes([]);
            });
    }

    const handleSentSunat = () => {
        dispatch(sendSunatSelectedCreditNotes({selectedCreditNotes}))
            .unwrap()
            .finally(() => {
                setSelectedCreditNotes([]);
            });
    }

    const handleCancelCreditNote = (creditNoteId) => {
        dispatch(cancelSentCreditNote({creditNoteId}))
            .unwrap()
            .finally(() => {
                setSelectedCreditNotes([]);
            });
    }
    const columns = [
        {
            field: 'id',
            hide: true,
            filterable: false,
        },
        {
            field: 'creationDate',
            headerName: intl.messages['date'],
            valueFormatter: ({value}) => parseDateToDateString(value),
            width: 200,
            filterable: false,
        },
        {
            field: 'billNumber',
            headerName: intl.messages['num'],
            width: 200,
            filterable: false,
        },
        {
            field: 'clientName',
            headerName: intl.messages['client'],
            width: 200,
        },
        {
            field: 'subtotal',
            headerName: intl.messages['subtotal'],
            valueFormatter: ({value}) => `${parseNumberToMoney(value)}`,
            width: 200,
            filterable: false,
        },
        {
            field: 'total',
            headerName: intl.messages['total'],
            valueFormatter: ({value}) => `${parseNumberToMoney(value)}`,
            width: 200,
            filterable: false,
        },
        {
            field: 'sunatSent',
            headerName: intl.messages['sunat.accepted'],
            valueFormatter: ({value}) => value? intl.messages['yes'] : intl.messages['no'],
            width: 150,
        },
        {
            field: 'state',
            headerName: intl.messages['state'],
            width: 200,
            renderCell: ({value, row}) =>
                (<Typography
                    style={{
                        color: '#fff',
                        backgroundColor: row.stateColor ?? '#000',
                        padding: '4px 8px',
                    }}
                >
                    {value}
                </Typography>)

        },
        {
            field: 'actions',
            headerName: intl.messages["table.edit"],
            width: 180,
            type: 'actions',
            renderCell: ({row}) => {
                return row?.state?.toLowerCase()?.includes("pendiente") && !row?.sunatSent
                    ?(
                        <>
                            <IconButton onClick={() => handleEditTax(row)}>
                                <EditIcon/>
                            </IconButton>
                            <IconButton onClick={() => handleDeleteTax(row)}>
                                <DeleteIcon/>
                            </IconButton>
                        </>
                    )
                    : <>
                        <IconButton onClick={() => handleEditTax(row)}>
                            <ShowIcon/>
                        </IconButton>
                        {row.sunatSent
                            && (
                                <>
                                    <DownloadMenu creditNote={row}/>
                                    {row.stateId !== "4" &&
                                        <IconButton onClick={() => handleCancelCreditNote(row.id)}>
                                            <CancelIcon/>
                                        </IconButton>
                                    }
                                </>
                            )
                        }
                    </>
            }
        }
    ]
    return (
        <>
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '1rem',
                    marginBottom: '1rem'
                }}
            >
                <ContainedButton
                    text={intl.messages['send.sunat']}
                    color='primary'
                    onClick={handleSentSunat}
                    disabled={selectedCreditNotes?.some(creditNoteId => !creditNotes[creditNoteId].state?.toLowerCase().includes('facturado') || +creditNotes[creditNoteId]?.total <= 0 || +creditNotes[creditNoteId]?.subtotal <= 0)}
                />
            </Box>
            <DataGrid
                columns={columns}
                rows={creditNotesList}
                style={{
                    height: '70vh'
                }}
                disableColumnMenu
                disableSelectionOnClick
                checkboxSelection
                selectionModel={selectedCreditNotes}
                onSelectionModelChange={(newSelection) => {
                    setSelectedCreditNotes(newSelection);
                }}
                components={{Toolbar: FilterToolbar}}
            />
        </>
    );
};

export default Table;