import React from 'react';
import {Redirect, Route, Switch} from 'react-router';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import Error404 from './Pages/404';
import Login from './Auth/Login';
import Register from './Auth/Register';
import ForgotPasswordPage from './Auth/ForgotPassword';

import AdministratorCashManagersView from './Pages/Administrator/CashManagers';
import AdministratorSupervisorsView from './Pages/Administrator/Supervisors';
import AdministratorGuardiansView from './Pages/Administrator/Guardians';
import AdministratorRejectionTypesView from './Pages/Administrator/RejectionTypes';
import AdministratorExpenseTypesView from './Pages/Administrator/ExpenseTypes';
import AdministratorPettyCashTypesView from './Pages/Administrator/PettyCashTypes';
import AdministratorAdvanceTypesView from './Pages/Administrator/AdvanceTypes';
import AdministratorLogHistoryView from './Pages/Administrator/LogHistory';
import AdministratorProductsView from './Pages/Administrator/Products';

import AssignAdvance from './Pages/GuardiansPages/AssignAdvance';
import Operative from './Pages/GuardiansPages/Operative';
import AdvancesList from './Pages/GuardiansPages/AdvancesList';
import GuardianStockView from './Pages/GuardiansPages/Stock';
import AccountingList from './Pages/GuardiansPages/AccountingList';
import AddExpenses from './Pages/GuardiansPages/AddExpenses';
import CheckStock from './Pages/Logistic/Inventory/check-stock/index';

import WorkOrders from './Pages/Logistic/WorkOrders/index';
import MerchandiseReception from './Pages/Logistic/Inventory/merchandise-reception/index';

import SupervisorUnresolvedExpensesView from './Pages/Supervisor/UnresolvedExpenses';
import SupervisorsUnresolvedAdvancesView from './Pages/Supervisor/UnresolvedAdvances';

import LogisticIncidenceTypesView from './Pages/Administrator/IncidenceTypes';
import LogisticWarehouseView from './Pages/Administrator/Warehouse';
import LogisticIncidentView from './Pages/Administrator/Incident';
import LogisticWarehouseTransferView from './Pages/Administrator/WarehouseTransfer';
import LogisticRegulationView from './Pages/Administrator/Regulation';
import LogisticTraceabilityView from './Pages/Administrator/Traceability';
import UnresolvedAccountingList from "./Pages/Administrator/UnresolvedAccountingList";
import Receivers from "./Pages/Administrator/Receivers";
import RejectionTypes from './Pages/Logistic/Distribution/DataMaster/RejectionTypes';
import Clients from "./Pages/Administrator/Clients";
import WorkOrderBreakdown from "./Pages/Administrator/WorkOrderBreakdown";
import Tags from "./Pages/Administrator/Tags";
import DataCollection from './Pages/Administrator/DataCollection';
import Dashboard from "./Pages/ControlPanel/Dashboard";
import Orders from './Pages/Logistic/Distribution/Order';
import OrderHistory from './Pages/Administrator/OrderHistory';
import Assignment from './Pages/Logistic/Assignment';
import Monitoring from "./Pages/Logistic/Monitoring";
import Employees from "./Pages/RRHH/Employees/index";
import PauseTypes from "./Pages/RRHH/Workday/pauseTypes";
import SigningType from "./Pages/RRHH/Workday/SigningType";
import DailyOperations from "./Pages/RRHH/Workday/DailyOperations";
import HistoricalSigning from "./Pages/RRHH/Workday/HistoricalSigning";
import ControlPanelReports from './Pages/ControlPanel/Reports';
import FleetManagement from './Pages/FleetManagement/Vehicle/File';
import Infractions from './Pages/FleetManagement/Vehicle/Infractions/';
import Sinister from './Pages/FleetManagement/Vehicle/Sinister/';
import Workshop from './Pages/FleetManagement/DailyOperation/Workshop/';
import Refueling from './Pages/FleetManagement/DailyOperation/Refueling/';
import Tires from './Pages/FleetManagement/DailyOperation/Tires/';
import Parts from './Pages/FleetManagement/DailyOperation/Parts/';
import Files from './Pages/FleetManagement/Tachograph/Files/';
import Alerts from './Pages/FleetManagement/Tachograph/Alerts/';
import Insurance from "./Pages/FleetManagement/Vehicle/Insurance/";
import Map from "./Pages/Localization/Maps/";
import Reserves from "./Pages/FleetManagement/DailyOperation/Reserves/";
import Users from "./Pages/Configuration/UserSettings/Users";
import Modules from "./Pages/Configuration/UserSettings/Modules";
import Roles from "./Pages/Configuration/UserSettings/Roles";
import OrganizationModules from "./Pages/Configuration/UserSettings/OrganizationModules";
import NotEntityId from "./Pages/NotEntityId";
import BasicHome from "./Pages/BasicHome";
import Changelog from "./Pages/System/Changelog";
import WorkOrdersTypes from "./Pages/Logistic/Distribution/DataMaster/WorkOrderTypes";
import ChecklistTypes from "./Pages/Logistic/Distribution/DataMaster/ChecklistTypes";
import DownloadApp from './Pages/DownloadApp/Download-app';
import RestrictedRoute from "./RestrictedRoute";
import UserConfigurationRoute from "./Roles/Configuration/UserConfigurationRoute";
import ControlPanelKpiRoute from "./Roles/ControlPanel/ControlPanelKpiRoute";
import FleetManagementVehiclesRoute from "./Roles/FleetManagement/FleetManagementVehiclesRoute";
import LocalizationRoute from "./Roles/Localization/LocalizationRoute";
import RrhhPersonalRoute from "./Roles/Rrhh/RrhhPersonalRoute";
import TreasuryCashManagementRoute from "./Roles/Treasury/TreasuryCashManagementRoute";
import LogisticInventoryRoute from "./Roles/Logistic/LogisticInventoryRoute";
import LogisticDistributionRoute from "./Roles/Logistic/LogisticDistributionRoute";
import FleetManagementDailyOperationRoute from "./Roles/FleetManagement/FleetManagementDailyOperationRoute";
import FleetManagementTachographRoute from "./Roles/FleetManagement/FleetManagementTachographRoute";
import RrhhWorkdayRoute from "./Roles/Rrhh/RrhhWorkdayRoute";
import ControlPanelGeneralRoute from "./Roles/ControlPanel/ControlPanelGeneralRoute";
import ElevatorsManagementElevatorsRoute from "./Roles/ElevatorsManagement/ElevatorsManagementElevatorsRoute";
import IndexCard from "./Pages/ElevatorsManagement/Elevators/DailyOperations/IndexCard";
import ModelTypes from "./Pages/ElevatorsManagement/Elevators/MasterData/ModelTypes";
import CategoryTypes from "./Pages/ElevatorsManagement/Elevators/MasterData/CategoryTypes";
import StateTypes from "./Pages/ElevatorsManagement/Elevators/MasterData/StateTypes";
import DetailTypes from "./Pages/ElevatorsManagement/Elevators/MasterData/DetailTypes";
import ContractTypes from "./Pages/ElevatorsManagement/Elevators/MasterData/ContractTypes";
import ServicesList from './Pages/Logistic/ServicesList';
import Traffic from "./Pages/FleetManagement/Tachograph/Traffic";
import ReturnTypes from "./Pages/Logistic/Distribution/DataMaster/ReturnTypes";
import ResendSignUp from "./Auth/ResendSignUp";
import Documentation from "./Pages/FleetManagement/Vehicle/Documentation";
import EmployeesDocumentation from "./Pages/RRHH/Documentation";
import VehicleRoles from "./Pages/Configuration/UserSettings/VehicleRoles";
import MaintenanceTypes from "./Pages/FleetManagement/DailyOperation/MaintenanceTypes";
import MaintenancePlans from "./Pages/FleetManagement/DailyOperation/MaintenancePlans";
import VehicleMaintenancePlans from "./Pages/FleetManagement/DailyOperation/VehicleMaintenancePlans";
import VehicleMaintenance from "./Pages/FleetManagement/DailyOperation/VehicleMaintenance";
import CorrectiveTypes from "./Pages/FleetManagement/DailyOperation/CorrectiveTypes";
import VehicleCorrectives from "./Pages/FleetManagement/DailyOperation/VehicleCorrectives";
import WorkshopRequest from "./Pages/FleetManagement/DailyOperation/WorkshopRequest";
import DocumentsTypes from "./Pages/FleetManagement/Vehicle/DocumentsTypes";
import EmployeesDocumentsTypes from "./Pages/RRHH/DocumentsTypes";
import SinisterTypes from "./Pages/FleetManagement/DailyOperation/SinisterTypes";
import WallMap from "./Pages/Localization/WallMap";
import Refund from "./Pages/GuardiansPages/Refunds";
import CarbonFootprint from "./Pages/Logistic/CarbonFootprint";
import BackOfficeDevicesRoute from "./Roles/BackOffice/BackOfficeDevicesRoute";
import Teltonika from "./Pages/BackOffice/Devices/Teltonika/Teltonika";
import BackOfficeLicensesRoutes from "./Roles/BackOffice/BackOfficeLicensesRoutes";
import HistoryData from "./Pages/BackOffice/Licenses/HistoryData";
import Planner from "./Pages/Logistic/Distribution/Planner";
import Parser from "./Pages/BackOffice/Devices/Management/Paser";
import AavvServices from "./Pages/Logistic/Distribution/AavvServices";
import IndexFiles from "./Pages/BackOffice/Licenses/IndexFiles";
import CostCenterTypes from "./Pages/Treasury/CostCenterTypes";
import FilesReader from "./Pages/FleetManagement/Tachograph/FilesReader";
import Cards from "./Pages/FleetManagement/Tachograph/Cards";
import Taxes from "./Pages/Treasury/Taxes";
import TreasuryServices from "./Pages/Treasury/TreasuryServices";
import Budgets from "./Pages/Treasury/Budgets";
import Bills from "./Pages/Treasury/Bills";
import DrivingControl from "./Pages/Localization/DrivingControl";
import GpsReports from "./Pages/ControlPanel/GpsReports";
import Providers from "./Pages/Logistic/Purchases/Providers";
import ProviderOrders from "./Pages/Logistic/Purchases/ProviderOrders";
import TreasuryInvoicingRoute from "./Roles/Treasury/TreasuryInvoicingRoute";
import LogisticPurchasesRoute from "./Roles/Logistic/LogisticPurchasesRoute";
import GenreTypes from "./Pages/ElevatorsManagement/Elevators/MasterData/GenreTypes";
import ManufacturerTypes from "./Pages/ElevatorsManagement/Elevators/MasterData/ManufacturerTypes";
import EquipmentTypes from "./Pages/ElevatorsManagement/Elevators/MasterData/EquipmentTypes";
import CreditNotes from "./Pages/Treasury/CreditNotes";

export const Routes = () => {
  
  const { authUser, selectedCompany } = useSelector(({ auth }) => auth);
  const location = useLocation();

  if (location.pathname === '' || location.pathname === '/') {
    return <Redirect to={'/signin'} />;
  } else if (authUser && location.pathname === '/signin') {
    return <Redirect to={'/home-page'} />;
  }

  return (
    <React.Fragment>
      <Switch>
        <Route path="/signin" component={Login} />
        <Route path="/signup" component={Register} />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <Route path="/resend-signup" component={ResendSignUp}/>
        <Route path="/download-app" component={DownloadApp} />

        <TreasuryCashManagementRoute path="/treasury/cash-management/cash-managers" component={AdministratorCashManagersView} />
        <TreasuryCashManagementRoute path="/treasury/cash-management/supervisors" component={AdministratorSupervisorsView} />
        <TreasuryCashManagementRoute path="/treasury/cash-management/guardians" component={AdministratorGuardiansView} />
        <TreasuryCashManagementRoute path="/treasury/cash-management/rejections" component={AdministratorRejectionTypesView} />
        <TreasuryCashManagementRoute path="/treasury/cash-management/cost-center-types" component={CostCenterTypes} />
        <TreasuryCashManagementRoute path="/treasury/cash-management/expenses" component={AdministratorExpenseTypesView} />
        <TreasuryCashManagementRoute path="/treasury/cash-management/petty-cash" component={AdministratorPettyCashTypesView} />
        <TreasuryCashManagementRoute path="/treasury/cash-management/advance-payments" component={AdministratorAdvanceTypesView} />
        <TreasuryCashManagementRoute path="/treasury/cash-management/log-history" component={AdministratorLogHistoryView} />
        <TreasuryCashManagementRoute path="/treasury/cash-management/unresolved-accounting-list" component={UnresolvedAccountingList} />
        <TreasuryCashManagementRoute path="/treasury/cash-management/operative" component={Operative} />
        <TreasuryCashManagementRoute path="/treasury/cash-management/advances-list" component={AdvancesList} />
        <TreasuryCashManagementRoute path="/treasury/cash-management/add-advances" component={AssignAdvance} />
        <TreasuryCashManagementRoute path="/treasury/cash-management/accounting-list" component={AccountingList} />
        <TreasuryCashManagementRoute path="/treasury/cash-management/add-expenses" component={AddExpenses} />
        <TreasuryCashManagementRoute path="/treasury/cash-management/unresolved-expenses" component={SupervisorUnresolvedExpensesView} />
        <TreasuryCashManagementRoute path="/treasury/cash-management/unresolved-expenses" component={SupervisorUnresolvedExpensesView} />
        <TreasuryCashManagementRoute path="/treasury/cash-management/unresolved-advances" component={SupervisorsUnresolvedAdvancesView} />
        <TreasuryCashManagementRoute path="/treasury/cash-management/refund" component={Refund} />

        <TreasuryInvoicingRoute path="/treasury/invoicing/taxes" component={Taxes} />
        <TreasuryInvoicingRoute path="/treasury/invoicing/services" component={TreasuryServices} />
        <TreasuryInvoicingRoute path="/treasury/invoicing/budgets" component={Budgets} />
        <TreasuryInvoicingRoute path="/treasury/invoicing/bills" component={Bills} />
        <TreasuryInvoicingRoute path="/treasury/invoicing/credit-notes" component={CreditNotes} />

        <LogisticInventoryRoute path="/logistic/products" component={AdministratorProductsView} />
        <LogisticInventoryRoute path="/logistic/receivers" component={Receivers} />
        <LogisticInventoryRoute path ="/logistic/warehouse" component={LogisticWarehouseView} />
        <LogisticInventoryRoute path ="/logistic/types-incidence" component={LogisticIncidenceTypesView} />
        <LogisticInventoryRoute path ="/logistic/warehouse-transfer" component={LogisticWarehouseTransferView} />
        <LogisticInventoryRoute path ="/logistic/incidents" component={LogisticIncidentView} />
        <LogisticInventoryRoute path="/logistic/check-stock" component={GuardianStockView} />
        <LogisticInventoryRoute path="/logistic/check-stock" component={CheckStock} />
        <LogisticInventoryRoute path = "/logistic/traceability" component={LogisticTraceabilityView} />
        <LogisticInventoryRoute path ="/logistic/regulation" component={LogisticRegulationView} />
        <LogisticInventoryRoute path="/logistic/merchandiseReception" component={MerchandiseReception} />

        <LogisticDistributionRoute path = "/logistic/rejection" component={RejectionTypes} />
        <LogisticDistributionRoute path="/logistic/distribution/work-orders" component={WorkOrders} />
        <LogisticDistributionRoute path="/logistic/distribution/ot-breakdown" component={WorkOrderBreakdown} />
        {selectedCompany?.name === "CDS" && <LogisticDistributionRoute path="/logistic/distribution/dataCollection" component={DataCollection}/>}
        <LogisticDistributionRoute path="/logistic/distribution/orders" component={Orders} />
        <LogisticDistributionRoute path="/logistic/distribution/ordersHistory" component={OrderHistory} />
        <LogisticDistributionRoute path="/logistic/distribution/assignment" component={Assignment} />
        <LogisticDistributionRoute path="/logistic/distribution/monitoring" component={Monitoring} />
        <LogisticDistributionRoute path="/logistic/distribution/work-orders-types" component={WorkOrdersTypes}/>
        <LogisticDistributionRoute path="/logistic/distribution/checklist-types" component={ChecklistTypes}/>
        <LogisticDistributionRoute path="/logistic/distribution/services-list" component={ServicesList}/>
        <LogisticDistributionRoute path="/logistic/distribution/carbon-footprint" component={CarbonFootprint}/>
        <LogisticDistributionRoute path="/logistic/distribution/return-types" component={ReturnTypes}/>
        <LogisticDistributionRoute path="/logistic/distribution/planner" component={Planner}/>
        <LogisticDistributionRoute path="/logistic/distribution/aavv-services" component={AavvServices}/>

        <LogisticPurchasesRoute path="/logistic/purchases/providers" component={Providers}/>
        <LogisticPurchasesRoute path="/logistic/purchases/provider-orders" component={ProviderOrders}/>



        <RrhhPersonalRoute path = "/rrhh/personal/workers" component={Employees} />
        <RrhhPersonalRoute path = "/rrhh/personal/employees-documentation" component={EmployeesDocumentation} />
        <RrhhPersonalRoute path = "/rrhh/personal/documents-types" component={EmployeesDocumentsTypes} />

        <RrhhWorkdayRoute path = "/rrhh/workday/pause/types" component={PauseTypes} />
        <RrhhWorkdayRoute path = "/rrhh/workday/signing/types" component={SigningType} />
        <RrhhWorkdayRoute path = "/rrhh/workday/daily/operations" component={DailyOperations} />
        <RrhhWorkdayRoute path = "/rrhh/workday/historical/signing" component={HistoricalSigning} />

        <ControlPanelGeneralRoute path="/control-panel/general/clients" component={Clients} />
        <ControlPanelGeneralRoute path="/control-panel/general/tags" component={Tags} />

        <ControlPanelKpiRoute path = "/control-panel/kpi/reports" component={ControlPanelReports} />
        <ControlPanelKpiRoute path="/control-panel/kpi/dashboard" component={Dashboard}/>
        <ControlPanelKpiRoute path="/control-panel/kpi/gps" component={GpsReports}/>

        <FleetManagementVehiclesRoute path = "/fleet-management/vehicles/documents-types" component={DocumentsTypes} />
        <FleetManagementVehiclesRoute path = "/fleet-management/vehicles/file" component={FleetManagement} />
        <FleetManagementVehiclesRoute path = "/fleet-management/vehicles/documentation" component={Documentation} />
        <FleetManagementVehiclesRoute path = "/fleet-management/vehicles/secure" component={Insurance} />
        <FleetManagementVehiclesRoute path = "/fleet-management/vehicles/infractions" component={Infractions} />
        <FleetManagementVehiclesRoute path = "/fleet-management/vehicles/sinister" component={Sinister} />

        <FleetManagementDailyOperationRoute path = "/fleet-management/daily-operations/workshop-requests" component={WorkshopRequest} />
        <FleetManagementDailyOperationRoute path = "/fleet-management/daily-operations/workshop" component={Workshop} />
        <FleetManagementDailyOperationRoute path = "/fleet-management/daily-operations/refueling" component={Refueling} />
        <FleetManagementDailyOperationRoute path = "/fleet-management/daily-operations/tires" component={Tires} />
        <FleetManagementDailyOperationRoute path = "/fleet-management/daily-operations/parts" component={Parts} />
        <FleetManagementDailyOperationRoute path = "/fleet-management/daily-operations/reserves" component={Reserves} />
        <FleetManagementDailyOperationRoute path = "/fleet-management/daily-operations/sinister-types" component={SinisterTypes} />
        <FleetManagementDailyOperationRoute path = "/fleet-management/daily-operations/corrective-types" component={CorrectiveTypes} />
        <FleetManagementDailyOperationRoute path = "/fleet-management/daily-operations/vehicle-correctives" component={VehicleCorrectives} />
        <FleetManagementDailyOperationRoute path = "/fleet-management/daily-operations/maintenance-types" component={MaintenanceTypes} />
        <FleetManagementDailyOperationRoute path = "/fleet-management/daily-operations/maintenance-plans" component={MaintenancePlans} />
        <FleetManagementDailyOperationRoute path = "/fleet-management/daily-operations/vehicle-maintenance-plans" component={VehicleMaintenancePlans} />
        <FleetManagementDailyOperationRoute path = "/fleet-management/daily-operations/vehicle-maintenance" component={VehicleMaintenance} />

        <FleetManagementTachographRoute path = "/fleet-management/tachograph/files" component={Files} />
        <FleetManagementTachographRoute path = "/fleet-management/tachograph/files-reader" component={FilesReader} />
        <FleetManagementTachographRoute path = "/fleet-management/tachograph/alerts" component={Alerts} />
        <FleetManagementTachographRoute path = "/fleet-management/tachograph/traffic" component={Traffic} />
        <FleetManagementTachographRoute path = "/fleet-management/tachograph/cards" component={Cards} />

        <LocalizationRoute path = "/localization/daily-operations/map" component={Map} />
        <LocalizationRoute path = "/localization/daily-operations/wall-map" component={WallMap} />
        <LocalizationRoute path = "/localization/daily-operations/driving-control" component={DrivingControl} />

        <UserConfigurationRoute path = "/configurations/user-settings/users" component={Users} />
        <UserConfigurationRoute path = "/configurations/user-settings/modules" component={Modules} />
        <UserConfigurationRoute path = "/configurations/user-settings/roles" component={Roles} />
        <UserConfigurationRoute path = "/configurations/user-settings/organization-modules" component={OrganizationModules} />
        <UserConfigurationRoute path = "/configurations/user-settings/vehicle-roles" component={VehicleRoles} />

        <ElevatorsManagementElevatorsRoute path="/elevators-management/elevators/index-cards" component={IndexCard}/>
        <ElevatorsManagementElevatorsRoute path="/elevators-management/elevators/model-types" component={ModelTypes} />
        <ElevatorsManagementElevatorsRoute path="/elevators-management/elevators/category-types" component={CategoryTypes} />
        <ElevatorsManagementElevatorsRoute path="/elevators-management/elevators/state-types" component={StateTypes} />
        <ElevatorsManagementElevatorsRoute path="/elevators-management/elevators/detail-types" component={DetailTypes} />
        <ElevatorsManagementElevatorsRoute path="/elevators-management/elevators/contract-types" component={ContractTypes} />
        <ElevatorsManagementElevatorsRoute path="/elevators-management/elevators/genre-types" component={GenreTypes} />
        <ElevatorsManagementElevatorsRoute path="/elevators-management/elevators/manufacturer-types" component={ManufacturerTypes} />
        <ElevatorsManagementElevatorsRoute path="/elevators-management/elevators/types" component={EquipmentTypes} />

        <BackOfficeDevicesRoute path='/back-office/devices/teltonika' component={Teltonika} />
        <BackOfficeDevicesRoute path='/back-office/devices/parser' component={Parser} />
        <BackOfficeLicensesRoutes path='/back-office/licenses/history-data' component={HistoryData} />
        <BackOfficeLicensesRoutes path='/back-office/licenses/management/index-files' component={IndexFiles} />

        <RestrictedRoute path="/changelog" component={Changelog} />

        <Route path="/no-company" component={NotEntityId} />
        {/*<RestrictedRoute path="/home-page" component={SamplePage} />*/}
        <RestrictedRoute path="/home-page" component={BasicHome} />
        <Route component={Error404} />
       
      </Switch>
    </React.Fragment>
  );
};
